import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import notificationStyles from "../shared/styles/notification.styles";
export default [baseStyles, notificationStyles, css`
    :host {
      --_bi-toast-background-color: var(--bi-color-success-shade);
      --_bi-toast-close-background-color-hover: var(--bi-color-success-default);
    }

    :host([variant="danger"]) {
      --_bi-toast-background-color: var(--bi-color-error-shade);
      --_bi-toast-close-background-color-hover: var(--bi-color-error-default);
    }

    .bi-toast {
      inline-size: 100%;
      display: flex;
      align-items: center;
      gap: var(--bi-size-space-m);
      padding: var(--bi-size-space-m);
      padding-inline-end: var(--bi-size-space-xs);
      background-color: var(--_bi-toast-background-color);
      color: var(--bi-color-text-on-dark);
      animation: notification-enter var(--bi-transition-duration-slow)
        var(--bi-transition-timing-default) forwards 1;
      border-radius: var(--bi-size-border-radius-m);
      box-shadow: 0px 3px 5px 0 rgb(0 0 0 / 15%);
      font-size: var(--bi-size-font-s);
    }

    .bi-toast--dismissed {
      animation-name: notification-exit;
    }

    .bi-toast-content {
      flex: 1;
      overflow: hidden;
      overflow-wrap: break-word;
    }

    .bi-toast-close {
      align-self: baseline;
      margin-block: calc(var(--bi-size-space-m) / -2);
      --bi-button-color-outline: transparent;
      --bi-button-color: var(--bi-color-text-on-dark);
      --bi-button-color--hover: var(--bi-color-text-on-dark);
      --bi-button-background-color--hover: var(
        --_bi-toast-close-background-color-hover
      );
    }

    .bi-toast-close-icon {
      flex: none;
      block-size: var(--bi-size-space-xs);
    }
  `];
