import { inject, Injectable } from '@angular/core'
import { ToastService } from '@mybingli/toast-service'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private translateService = inject(TranslateService)
  private toastService = inject(ToastService)

  showCopySnackbar(copied: boolean) {
    if (copied) this.success('copied')
    else this.showGenericErrorMessage()
  }

  showGenericErrorMessage() {
    this.error('oops_error_title')
  }

  success(messageKey: string) {
    const message = this.translateService.instant(messageKey)
    this.addToast(message)
  }

  languageChangeSuccess(messageKey: string) {
    this.translateService
      .getStreamOnTranslationChange(messageKey)
      .pipe(take(1))
      .subscribe(message => this.addToast(message))
  }

  error(messageKey: string) {
    const message = this.translateService.instant(messageKey)
    this.addToast(message, true)
  }

  private addToast(message: string, danger = false) {
    this.toastService.add(message, { variant: danger ? 'danger' : undefined })
  }
}
