import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      display: inline-flex;
      --_bi-badge-color: var(--bi-color-text-default);
      --_bi-badge-background-color: var(--bi-color-neutral-faint);
      --_bi-badge-border-color: var(--bi-color-neutral-shade);
      --_bi-badge-border-weight: var(--bi-size-border-weight);
      --_bi-badge-font-weight: var(--bi-font-weight-semibold);
    }

    :host([variant="success"]) {
      --_bi-badge-color: var(--bi-color-success-shade);
      --_bi-badge-background-color: var(--bi-color-success-faint);
      --_bi-badge-border-color: var(--bi-color-success-default);
    }

    :host([variant="warning"]) {
      --_bi-badge-color: var(--bi-color-warning-default);
      --_bi-badge-background-color: var(--bi-color-warning-faint);
      --_bi-badge-border-color: var(--bi-color-warning-default);
    }

    :host([variant="error"]) {
      --_bi-badge-color: var(--bi-color-error-default);
      --_bi-badge-background-color: var(--bi-color-error-faint);
      --_bi-badge-border-color: var(--bi-color-error-default);
    }

    :host([variant="ai"]) {
      --_bi-badge-color: var(--bi-color-ai-default);
      --_bi-badge-background-color: var(--bi-color-ai-faint);
      --_bi-badge-border-color: var(--bi-color-ai-default);
      --_bi-badge-font-weight: var(--bi-font-weight-regular);
      --_bi-badge-border-weight: 0;
    }
    :host([variant^="triage-"]) {
      --_bi-badge-min-inline-size: var(--bi-size-control-block-xs);
      --_bi-badge-color: var(--bi-color-text-on-dark);
      --_bi-badge-border-weight: 0;
    }
    :host([variant="triage-1"]) {
      --_bi-badge-background-color: var(--bi-color-triage-1);
    }
    :host([variant="triage-2"]) {
      --_bi-badge-background-color: var(--bi-color-triage-2);
    }
    :host([variant="triage-3"]) {
      --_bi-badge-background-color: var(--bi-color-triage-3);
    }
    :host([variant="triage-4"]) {
      --_bi-badge-background-color: var(--bi-color-triage-4);
    }
    :host([variant="triage-other"]) {
      --_bi-badge-background-color: var(--bi-color-triage-other);
    }

    .bi-badge {
      display: inline-flex;
      align-items: center;
      padding-inline: var(--bi-size-space-xs);
      gap: var(--bi-size-space-xs);
      color: var(--_bi-badge-color);
      background-color: var(--_bi-badge-background-color);
      border: var(--_bi-badge-border-weight) solid var(--_bi-badge-border-color);
      border-radius: var(--bi-size-border-radius-s);
      block-size: var(--bi-size-control-block-xs);
      min-inline-size: var(--_bi-badge-min-inline-size, auto);
      justify-content: center;
      font-size: var(--bi-size-font-xs);
      font-weight: var(--_bi-badge-font-weight);
      line-height: var(--bi-size-control-block-xs);
    }

    .bi-badge-aiIcon {
      flex: none;
      block-size: var(--bi-size-font-s);
      color: var(--bi-color-ai-default);
      vertical-align: middle;
    }
  `];
