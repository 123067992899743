import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import formControlStyles from "../shared/styles/form-control.styles";
export default [baseStyles, formControlStyles, css`
    /** Base */
    :host {
      --_bi-radio-size: var(--bi-size-space-m);
      --_bi-radio-control-color: var(--bi-color-border-default);
      --_bi-radio-control-background-color: var(--bi-color-background);
      --_bi-radio-box-shadow: inset 0 0 0 2px var(--bi-color-background);
      --_bi-radio-color-outline: var(--bi-color-outline);
      display: inline-grid;
      grid-template-columns: auto 1fr;
      gap: var(--bi-size-space-s);
    }

    /** Checked */
    :host([checked]) {
      --_bi-radio-control-color: var(--bi-color-primary-shade);
      --_bi-radio-control-background-color: var(--bi-color-primary-shade);
      --_bi-radio-color-outline: var(--bi-color-primary-default);
    }

    /** Focus */
    .bi-radio-control:focus-visible {
      --_bi-radio-box-shadow: 0 0 0 1px var(--bi-color-background),
        0 0 0 calc(var(--bi-size-border-weight) + 1px)
          var(--_bi-radio-color-outline),
        inset 0 0 0 2px var(--bi-color-background);
    }

    /** Error */
    :host([error]) {
      --_bi-radio-control-color: var(--bi-color-error-default);
      --_bi-radio-color-outline: var(--bi-color-error-default);
    }
    :host([error][checked]) {
      --_bi-radio-control-background-color: var(--bi-color-error-default);
    }

    /** Radio */
    .bi-radio {
      position: relative;
      display: flex;
      align-items: center;
    }
    .bi-radio.bi-radio--hasLabel {
      block-size: calc(var(--bi-size-font-m) * var(--bi-line-height));
    }
    .bi-radio-control {
      block-size: var(--_bi-radio-size);
      inline-size: var(--_bi-radio-size);
      border: var(--bi-size-border-weight) solid var(--_bi-radio-control-color);
      box-shadow: var(--_bi-radio-box-shadow);
      background-color: var(--_bi-radio-control-background-color);
      border-radius: var(--bi-size-border-radius-pill);
    }
    .bi-radio-control:not(:disabled):active {
      opacity: var(--bi-opacity-active);
    }

    /** Content */
    .bi-radio-content {
      display: var(--_bi-radio-content-display, flex);
      flex-direction: column;
    }
    .bi-radio-content-main {
      display: flex;
    }
    .bi-radio-content .bi-form-control-label {
      font-weight: var(--bi-font-weight-normal);
      font-size: var(--bi-size-font-m);
      padding-inline-start: var(--bi-size-space-s);
      margin-inline-start: calc(var(--bi-size-space-s) * -1);
    }

    /** Custom Slot */
    .bi-radio-custom {
      cursor: pointer;
    }
    .bi-radio-custom.bi-radio-custom--empty {
      display: none;
    }

    /** Nested */
    .bi-radio-nested {
      display: none;
    }
    :host([show-nested]) .bi-radio-nested,
    :host([checked]) .bi-radio-nested.bi-radio-nested--hasNestedElements {
      display: flex;
      grid-column: 1 / -1;
      grid-row: 2;
      padding-inline-start: calc(
        var(--_bi-radio-size) + var(--bi-size-space-s)
      );
    }
    :host([show-nested]) .bi-form-control-label-chevron,
    :host([checked]) .bi-form-control-label-chevron {
      transform: rotate(90deg);
    }
  `];
