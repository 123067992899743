var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { state } from "lit/decorators.js";
import { ContextConsumer } from "@lit/context";
import { fieldsetContext } from "../contexts/fieldset.context";
export const FieldsetAwareMixin = superClass => {
  class FieldsetAwareElement extends superClass {
    constructor() {
      super(...arguments);
      this.fieldsetContext = {
        disabled: false
      };
    }
    async connectedCallback() {
      // Using this approach instead of the @consumer decorator for better timing. See https://github.com/lit/lit/issues/4618
      super.connectedCallback();
      await this.updateComplete;
      this.fieldsetConsumer = new ContextConsumer(this, {
        context: fieldsetContext,
        subscribe: true
      });
    }
    willUpdate() {
      var _a;
      this.fieldsetContext = ((_a = this.fieldsetConsumer) === null || _a === void 0 ? void 0 : _a.value) || {
        disabled: false
      };
    }
  }
  __decorate([state()], FieldsetAwareElement.prototype, "fieldsetContext", void 0);
  return FieldsetAwareElement;
};
