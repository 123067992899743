import {
  AppointmentStatusAction,
  CommunicationTypes,
  Country,
  Hcs,
  HcsOption,
  SurveyUrl,
  Tenant
} from '../data/models/client.model'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { BossApiListResponse, BossApiResponse } from '../data/models/boss-api-response'
import { CommunicationServiceTypes, PatientData } from 'src/app/modules/dashboard/data/dashboard.model'
import { computed, effect, Injectable, Signal, signal, WritableSignal } from '@angular/core'

import { AddPatientDTO } from 'src/app/modules/dashboard/patients/add-patient/add-patient-dto'
import { ApiRequest } from '../data/models/api-request'
import { BingliAuthService } from '@mybingli/security-service'
import { Clipboard } from '@angular/cdk/clipboard'
import { GlobalLoaderService } from './global-loader.service'
import { HttpClient } from '@angular/common/http'
import { SnackbarService } from './snackbar.service'
import { environment } from 'src/environments/environment'
import { defaultCountryPhoneCode, localeUS, localStorageDateLocaleKey, phoneCodeUS } from '../data/constants'
import { finalize, map, mergeMap, take } from 'rxjs/operators'
import { ActionType } from 'src/app/modules/dashboard/data/dashboard.enums'
import { SurveyQueryQueryVariables } from 'src/app/graphql/surveys/surveys.generated'
import { getCountriesPhoneInformation } from '@shared/components/date-picker/helper'
import { CountryIsoCode } from '@shared/data/country-iso-code'
import { LoaderKey } from '../../modules/dashboard/data/dashboard.constants'
import { ModalService } from '@shared/services/modal.service'
import { PractitionerService } from './practitioner.service'

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private countriesSubject = new BehaviorSubject<Country[]>([])
  private tenantDefaultCountrySubject = new BehaviorSubject<Country | null>(null)
  private currentTenantIdSubject = new BehaviorSubject<string | null>(null)
  private currentHcsId: WritableSignal<string | null> = signal<string | null>(null)
  private currentHcsSubject = new BehaviorSubject<Hcs | null>(null)
  private currentTenantSubject = new BehaviorSubject<Tenant | null>(null)
  public currentTenant = signal<Tenant | null>(null)
  public currentTenantGuidelineSet = signal(false)
  private currentHcsAvailableActionsSubject = new BehaviorSubject<AppointmentStatusAction[]>([])
  private currentHcsAvailableCommunicationTypesSubject = new BehaviorSubject<CommunicationTypes[]>([])

  public availableHcs: WritableSignal<HcsOption[]> = signal<HcsOption[]>([])
  private archiveAction = signal<AppointmentStatusAction | null>(null)
  private actionLookupSubject: BehaviorSubject<Map<string, AppointmentStatusAction>> = new BehaviorSubject(new Map())
  public actionsLookup$ = this.actionLookupSubject.asObservable()
  public hasAvailableHcs = computed(() => Boolean(this.availableHcs().length))
  public currentHcsOption = computed(
    () => this.availableHcs().find(hcs => hcs.healthCareServiceId === this.currentHcsId()) || null
  )
  pendingMissingActions = new Map<string, Observable<AppointmentStatusAction>>()
  public selectedHcsIsValid = computed(() => Boolean(this.currentHcsOption()))
  public countries$: Observable<Country[]> = this.countriesSubject.asObservable()
  public tenantDefaultCountry$: Observable<Country | null> = this.tenantDefaultCountrySubject.asObservable()
  public useUSFormat$ = this.tenantDefaultCountry$.pipe(map(country => this.shouldUseUSFormat(country)))
  public currentHcs$: Observable<Hcs | null> = this.currentHcsSubject.asObservable()
  public currentHcsAvailableActions$: Observable<AppointmentStatusAction[]> =
    this.currentHcsAvailableActionsSubject.asObservable()
  public excludeArchivedSurveysFilter: Signal<SurveyQueryQueryVariables> = computed(() => {
    const archiveActionId = this.archiveAction()?.id
    return archiveActionId ? { actionToExclude: archiveActionId } : {}
  })

  public currentHcsAvailableCommunicationTypes$: Observable<CommunicationTypes[]> =
    this.currentHcsAvailableCommunicationTypesSubject.asObservable()
  public countryIdToCountryLookup: Map<string, Country> = new Map<string, Country>()
  public countryCodeToCountryIdLookup: Map<string, string> = new Map<string, string>()

  public currentHcsChange: Subject<void> = new Subject<void>()

  get tenantLang(): string | null {
    return this._tenantLang
  }

  get countries(): Country[] {
    return this.countriesSubject.value
  }

  get hcsId(): Hcs | null {
    return this.currentHcsSubject.value
  }

  get useUSFormat(): boolean {
    return this.shouldUseUSFormat(this.tenantDefaultCountrySubject.value)
  }

  get defaultCountry(): Country | null {
    return this.tenantDefaultCountrySubject.value ? this.tenantDefaultCountrySubject.value : this._fallbackCountry
  }

  private set countries(countries: Country[]) {
    this.countriesSubject.next(countries)
    const fallbackCountry = this.countriesSubject.value.find(country => country.countryCode === defaultCountryPhoneCode)
    if (fallbackCountry) this._fallbackCountry = fallbackCountry
  }

  private set tenantLang(lang: string | null) {
    this._tenantLang = lang
  }

  private set tenantDefaultCountry(regionCode: string) {
    const country = this.countriesSubject.value.find(country => country.countryCode === regionCode)
    if (regionCode) this.saveDateLocaleToLocalStorage(getCountriesPhoneInformation(country?.countryCode))
    this.tenantDefaultCountrySubject.next(country || null)
  }

  private _tenantLang: string | null = null
  private _fallbackCountry: Country | null = null

  constructor(
    private http: HttpClient,
    private authService: BingliAuthService,
    private clipboard: Clipboard,
    private globalLoader: GlobalLoaderService,
    private modal: ModalService,
    private snackbar: SnackbarService,
    private practitionerService: PractitionerService
  ) {
    effect(() => (this.selectedHcsIsValid() ? this.updateCurrentTenantAndHcsInfo() : null))
  }

  public initializeService() {
    this.fetchAvailableHcs()
  }

  private fetchAvailableHcs(): void {
    const url = `${environment.bossApi}/tenant-api/healthcareservices/user`

    this.http
      .get<BossApiResponse<HcsOption[]>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiResponse<HcsOption[]>) => {
          const availableHcs = response.data
          const { healthCareServiceId, tenantId } = response
          this.currentHcsId.set(healthCareServiceId)
          this.currentTenantIdSubject.next(tenantId)
          this.availableHcs.set(availableHcs)
          this.fetchAvailableActions()
          this.fetchArchiveAction()
          this.fetchAvailableCommunicationTypes()
          this.fetchCountries()
        },
        error: async () => {
          this.modal
            .createDialogModal({
              title: 'l_no_hcs_error_title',
              message: 'l_no_hcs_error_content',
              confirmShown: true,
              confirmLabel: 'close'
            })
            .then(modal => modal.instance.closed.pipe(take(1)).subscribe(() => this.authService.logout()))
        }
      })
  }
  private fetchAvailableActions() {
    const currentHcsId = this.currentHcsId()
    if (!currentHcsId) return
    const url = `${environment.bossApi}/tenant-api/healthcareservice/${currentHcsId}/actiontypes`

    this.http
      .get<BossApiListResponse<AppointmentStatusAction>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiListResponse<AppointmentStatusAction>) => {
          const availableActions = response.listData
          this.currentHcsAvailableActionsSubject.next(availableActions)
          const actionLookupMap = new Map<string, AppointmentStatusAction>()
          availableActions.forEach(action => {
            actionLookupMap.set(action.id, action)
          })

          this.actionLookupSubject.next(actionLookupMap)
        },
        error: (error: any) => {
          console.error('Failed to fetch available action types', error)
        }
      })
  }
  private fetchArchiveAction() {
    const url = `${environment.bossApi}/tenant-api/actiontype/name/${ActionType.archive}`

    this.http
      .get<BossApiResponse<AppointmentStatusAction>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiResponse<AppointmentStatusAction>) => this.archiveAction.set(response.data || null),
        error: (error: any) => {
          console.error('Failed to fetch archive action type', error)
        }
      })
  }

  getActionById(actionId: string): Observable<AppointmentStatusAction> {
    const url = `${environment.bossApi}/tenant-api/actiontype/${actionId}`

    return this.http.get<BossApiResponse<AppointmentStatusAction>>(url).pipe(
      take(1),
      map(response => response.data)
    )
  }

  fetchMissingActionById(actionId: string): Observable<AppointmentStatusAction> {
    const existingObservable = this.pendingMissingActions.get(actionId)
    if (!existingObservable) {
      const observable = this.getActionById(actionId).pipe(
        mergeMap(action => {
          const updatedActionLookupSubject = new Map(this.actionLookupSubject.value)
          updatedActionLookupSubject.set(actionId, action)
          this.actionLookupSubject.next(updatedActionLookupSubject)
          this.pendingMissingActions.delete(actionId)
          return of(action)
        })
      )
      this.pendingMissingActions.set(actionId, observable)
      return observable
    }
    return existingObservable
  }
  private fetchAvailableCommunicationTypes() {
    const currentHcsId = this.currentHcsId()
    if (!currentHcsId) return
    const url = `${environment.bossApi}/tenant-api/healthcareservice/${currentHcsId}/communicationTypes`

    this.http
      .get<BossApiListResponse<CommunicationTypes>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiListResponse<CommunicationTypes>) => {
          const availableCommunicationTypes = response.listData
          this.currentHcsAvailableCommunicationTypesSubject.next(availableCommunicationTypes)
        },
        error: (error: any) => {
          console.error('Failed to fetch available communication types', error)
        }
      })
  }

  private shouldUseUSFormat(country: Country | null): boolean {
    return country ? country.countryCode === phoneCodeUS : localStorage.getItem(localStorageDateLocaleKey) === localeUS
  }

  public changeHcs(hcsOption: HcsOption) {
    const { tenantId, healthCareServiceId } = hcsOption
    if (
      tenantId &&
      healthCareServiceId &&
      tenantId === this.currentTenantIdSubject.value &&
      healthCareServiceId === this.currentHcsId()
    )
      return
    this.globalLoader.startLoading(LoaderKey.changeHcs)
    const autoRefresh = false
    this.authService.refreshToken(tenantId, healthCareServiceId, autoRefresh)
    this.authService
      .tokenRefreshStream()
      .pipe(
        take(1),
        finalize(() => this.globalLoader.stopLoading(LoaderKey.changeHcs))
      )
      .subscribe(() => {
        this.currentHcsId.set(healthCareServiceId)
        this.currentTenantIdSubject.next(tenantId)
        this.updateCurrentTenantAndHcsInfo()
        this.currentHcsChange.next()
      })
  }

  public createPatient({ addPatientDto }: { addPatientDto: AddPatientDTO }) {
    const url = `${environment.bossApi}/tenant-api/patient`

    const body = new ApiRequest({ patient: addPatientDto.patient })

    return this.http.post<BossApiResponse<PatientData>>(url, body)
  }

  public openResumeSurveyUrl(surveyId: string, language: string) {
    const { bingli_token } = this.authService.getTokens()
    const url = `${environment.surveyUrl}/#/resume?surveyId=${surveyId}&token=${bingli_token}&language=${language}`
    window.open(url)
  }

  public sendInvites({
    careEpisodeId,
    patientId,
    inviteTypes,
    email,
    phoneNumber
  }: {
    careEpisodeId: string
    patientId: string
    inviteTypes: string[]
    email?: string
    phoneNumber?: string
  }) {
    const copyUrl = inviteTypes.includes('url')

    const communicationServiceTypes: CommunicationServiceTypes[] = []
    if (inviteTypes.includes('email')) communicationServiceTypes.push(CommunicationServiceTypes.Email)
    if (inviteTypes.includes('sms')) communicationServiceTypes.push(CommunicationServiceTypes.Sms)

    this.postInvites({ careEpisodeId, patientId, copyUrl, communicationServiceTypes, email, phoneNumber })
  }

  public postInvites({
    careEpisodeId,
    patientId,
    copyUrl,
    communicationServiceTypes,
    email,
    phoneNumber
  }: {
    careEpisodeId: string
    patientId: string
    copyUrl: boolean
    communicationServiceTypes: CommunicationServiceTypes[]
    email?: string
    phoneNumber?: string
  }) {
    const url = `${environment.surveyApi}/tenant-api/patient/${patientId}/invite/care-episode/${careEpisodeId}`

    const body = new ApiRequest({
      communicationServiceTypes,
      email,
      phoneNumber
    })

    this.http
      .post<BossApiResponse<SurveyUrl>>(url, body)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiResponse<SurveyUrl>) => {
          if (copyUrl) {
            const { surveyUrl } = response.data
            this.snackbar.showCopySnackbar(Boolean(surveyUrl) && this.clipboard.copy(surveyUrl))
          }

          if (communicationServiceTypes.length > 0) this.snackbar.success('invitation_sent')
        },
        error: (error: any) => {
          console.error('Failed to get survey Url', error)
          this.snackbar.error('invitation_failed')
        }
      })
  }

  public fetchCountries() {
    const url = `${environment.bossApi}/tenant-api/countries`

    const params = { pageSize: 300, sortOrder: 1, sortField: 1 }

    this.http
      .get<BossApiListResponse<Country>>(url, { params })
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiListResponse<Country>) => {
          const countries = response.listData || []
          this.countries = countries
          this.populateCountryLookups(countries)
        },
        error: (error: any) => {
          console.error('Failed to fetch countries', error)
        }
      })
  }

  private populateCountryLookups(countries: Country[]) {
    this.countryIdToCountryLookup.clear()
    this.countryCodeToCountryIdLookup.clear()

    countries.forEach(country => {
      this.countryIdToCountryLookup.set(country.id.toString(), country)
      if (country.isoAlpha2 in CountryIsoCode)
        this.countryCodeToCountryIdLookup.set(country.countryCode, country.id.toString())
    })
  }

  public deletePatient({ patientId }: { patientId: string }) {
    const url = `${environment.bossApi}/tenant-api/patient/${patientId}`

    return this.http.delete(url)
  }

  public updateCurrentTenantAndHcsInfo() {
    this.fetchCountries()
    this.fetchCurrentTenantInfo()
    this.fetchCurrentHcsInfo()
    this.fetchAvailableActions()
    this.fetchAvailableCommunicationTypes()
    this.practitionerService.initializePractitionerProfile()
  }

  private fetchCurrentTenantInfo(): void {
    const url = `${environment.bossApi}/tenant-api/tenant/current`

    this.http
      .get<BossApiResponse<Tenant>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiResponse<Tenant>) => {
          const tenant = response.data
          this.currentTenantSubject.next(tenant)
          this.currentTenant.set(tenant)
          this.currentTenantGuidelineSet.set(tenant.triageGuidelineSystemId !== undefined)
          this.tenantLang = tenant.defaultLanguage
          this.tenantDefaultCountry = tenant.regionCode ?? defaultCountryPhoneCode
        },
        error: (error: any) => {
          console.error('Failed to fetch current tenant info', error)
        }
      })
  }

  private fetchCurrentHcsInfo(): void {
    const url = `${environment.bossApi}/tenant-api/healthcareService/current`

    this.http
      .get<BossApiResponse<Hcs>>(url)
      .pipe(take(1))
      .subscribe({
        next: (response: BossApiResponse<Hcs>) => {
          const currentHcsInfo = response.data

          this.currentHcsSubject.next(currentHcsInfo)
        },
        error: (error: any) => {
          console.error('Failed to fetch current hcs info', error)
        }
      })
  }

  private saveDateLocaleToLocalStorage(dateLocale: 'en-US' | 'en-GB') {
    localStorage.setItem(localStorageDateLocaleKey, dateLocale)
  }

  public changeHcsToMainOrDefault() {
    const currentOption =
      this.availableHcs().find(x => x.isMain) || this.availableHcs().length ? this.availableHcs()[0] : null
    if (currentOption) this.changeHcs(currentOption)
  }
}
