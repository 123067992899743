import { css } from "lit";
export default css`
  /** Default */
  :host,
  [bi-unscoped-form-control] {
    --_bi-form-control-opacity: 1;
    --_bi-form-control-label-size: var(
      --bi-form-control-label-size,
      var(--bi-size-font-m)
    );
    --_bi-form-control-label-color: var(--bi-color-text-default);
  }

  /** Disabled */
  :host:has(.bi-form-control[disabled]),
  [bi-unscoped-form-control]:has(.bi-form-control[disabled]) {
    --_bi-form-control-opacity: var(--bi-opacity-disabled);
  }

  /** Error */
  :host([error]),
  [bi-unscoped-form-control][error] {
    --_bi-form-control-label-color: var(--bi-color-error-default);
  }

  /** Control */
  .bi-form-control {
    appearance: none;
    outline: none;
    margin: 0;
    display: block;
    opacity: var(--_bi-form-control-opacity);
  }
  .bi-form-control:not(:disabled) {
    cursor: pointer;
  }

  /** Label */
  .bi-form-control-label {
    display: flex;
    gap: var(--bi-size-space-xs);
    font-size: var(--_bi-form-control-label-size);
    font-weight: var(--bi-font-weight-semibold);
    line-height: var(--bi-line-height);
    color: var(--bi-color-text-default);
    opacity: var(--_bi-form-control-opacity);
    overflow-wrap: anywhere;
    cursor: pointer;
    ::slotted(*) {
      flex: none;
    }
  }
  :host:has(.bi-form-control[disabled]) .bi-form-control-label,
  [bi-unscoped-form-control]:has(.bi-form-control[disabled])
    .bi-form-control-label {
    cursor: default;
  }

  /** Label Chevron */
  .bi-form-control-label-chevron {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    inline-size: var(--bi-size-space-xs);
    color: var(--bi-color-text-bright);
    transition: transform var(--bi-transition-duration-normal)
      var(--bi-transition-timing-default);
  }

  /** Sublabel */
  .bi-form-control-sublabel {
    color: var(--bi-color-text-bright);
    font-size: var(--bi-size-font-s);
    opacity: var(--_bi-form-control-opacity);
    overflow-wrap: anywhere;
  }
  .bi-form-control-sublabel.bi-form-control-sublabel--empty {
    display: none;
  }

  /** Error */
  .bi-form-control-error {
    display: block;
    font-size: var(--bi-size-font-s);
    color: var(--bi-color-error-default);
    overflow-wrap: anywhere;
  }
`;
