var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import fieldsetStyles from "./fieldset.styles";
import { provide } from "@lit/context";
import { fieldsetContext } from "../shared/contexts/fieldset.context";
/**
 * A fieldset can be used for grouping sets of input components.
 * @slot - The content of the fieldset.
 */
let Fieldset = class Fieldset extends LitElement {
  constructor() {
    super(...arguments);
    /** Sets the disabled property on the fieldset and its child elements. */
    this.disabled = false;
    /** Marks the fieldset as required. */
    this.required = false;
    this.fieldsetContext = {
      disabled: this.disabled
    };
  }
  willUpdate(_changedProperties) {
    if (_changedProperties.has("disabled")) {
      this.fieldsetContext = {
        disabled: this.disabled
      };
    }
  }
  render() {
    return html`
      <fieldset
        class="bi-fieldset"
        ?disabled=${this.fieldsetContext.disabled}
        @change=${this.handleChanged}
        @focusin=${this.handleFocusIn}
        @focusout=${this.handleFocusOut}
        @keydown=${this.handleKeyDown}
      >
        <div class="bi-fieldset-inner">
          ${this.getHeaderHTML()}
          <div class="bi-fieldset-content">
            <slot @slotchange=${this.handleSlotChanged}></slot>
          </div>
        </div>
      </fieldset>
    `;
  }
  /** Returns the header HTML. */
  getHeaderHTML() {
    return this.label || this.sublabel || this.error ? html`
          <div class="bi-fieldset-header">
            ${this.getLegendHTML()} ${this.getSublabelHTML()}
            ${this.getErrorHTML()}
          </div>
        ` : nothing;
  }
  /** Returns the legend HTML. */
  getLegendHTML() {
    return this.label ? html`<legend class="bi-fieldset-label">
          ${this.label}${this.required ? "*" : ""}
        </legend>` : nothing;
  }
  /** Returns the sublabel HTML. */
  getSublabelHTML() {
    return this.sublabel ? html`<div class="bi-fieldset-sublabel">${this.sublabel}</div>` : nothing;
  }
  /** Returns the error HTML. */
  getErrorHTML() {
    return this.error ? html`<div class="bi-fieldset-error" role="alert">${this.error}</div>` : nothing;
  }
  handleSlotChanged() {
    this.radios = Array.from(this.querySelectorAll("bi-radio")).filter(radio => radio.closest("bi-fieldset") === this);
  }
  handleChanged(event) {
    if (this.radios.length) {
      const target = event.target;
      if (this.radios.includes(target)) {
        this.radios.filter(radio => radio !== target).forEach(radio => radio.checked = false);
      }
    }
  }
  handleFocusIn(event) {
    var _a;
    const radioTarget = event.target;
    if (this.radios.includes(radioTarget)) {
      (_a = this.radios.find(radio => radio.checked)) === null || _a === void 0 ? void 0 : _a.focus();
      this.radios.filter(radio => radio !== radioTarget).forEach(radio => radio.tabIndex = -1);
    } else {
      this.radios.forEach(radio => radio.tabIndex = -1);
    }
  }
  handleFocusOut() {
    this.radios.forEach(radio => radio.removeAttribute("tabindex"));
  }
  handleKeyDown(event) {
    if (this.radios.includes(event.target) && ["ArrowDown", "ArrowRight", "ArrowUp", "ArrowLeft"].includes(event.key)) {
      event.preventDefault();
      const radioCheckedIndex = this.radios.findIndex(radio => radio.checked);
      this.radios.forEach(radio => radio.removeAttribute("checked"));
      // Next
      if (["ArrowDown", "ArrowRight"].includes(event.key)) {
        const nextRadio = this.radios.find((radio, index) => index > radioCheckedIndex && !radio.disabled);
        if (nextRadio) {
          nextRadio.check();
        } else {
          const firstRadio = this.radios.find(radio => !radio.disabled);
          if (firstRadio) {
            firstRadio.check();
          }
        }
      }
      // Previous
      else if (["ArrowUp", "ArrowLeft"].includes(event.key)) {
        const previousRadioIndex = this.radios.slice(0, radioCheckedIndex).reverse().findIndex(radio => !radio.disabled);
        if (radioCheckedIndex !== -1 && previousRadioIndex !== -1) {
          this.radios[radioCheckedIndex - previousRadioIndex - 1].check();
        } else {
          const lastRadioIndex = this.radios.slice().reverse().findIndex(radio => !radio.disabled);
          if (lastRadioIndex !== -1) {
            this.radios[this.radios.length - lastRadioIndex - 1].check();
          }
        }
      }
    }
  }
};
Fieldset.styles = fieldsetStyles;
__decorate([property()], Fieldset.prototype, "label", void 0);
__decorate([property()], Fieldset.prototype, "sublabel", void 0);
__decorate([property()], Fieldset.prototype, "error", void 0);
__decorate([property({
  type: Boolean
})], Fieldset.prototype, "disabled", void 0);
__decorate([property({
  type: Boolean
})], Fieldset.prototype, "required", void 0);
__decorate([provide({
  context: fieldsetContext
}), state()], Fieldset.prototype, "fieldsetContext", void 0);
Fieldset = __decorate([customElement("bi-fieldset")], Fieldset);
export { Fieldset };
