var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";
import checkboxStyles from "./checkbox.styles";
import { FormControl } from "../shared/components/form-control/form-control.component";
/**
 * Checkboxes allow users to choose one or more options from a limited set of options. Use Fieldset to group multiple checkboxes together.
 * @slot - Custom content to show inside the checkbox. You will have to provide the label, sublabel and error yourself.
 * @slot nested - The content to show when the checkbox is checked.
 * @slot label-start - The content to show before the label. Useful for an icon, for example.
 * @slot label-end - The content to show after the label. Useful for a tooltip toggle, for example.
 */
let Checkbox = class Checkbox extends FormControl {
  constructor() {
    super(...arguments);
    /** Controls whether the checkbox is checked or not. */
    this.checked = false;
    /** Controls whether the checkbox is indeterminate or not. Automatically set to false when the checkbox is toggled. */
    this.indeterminate = false;
    /** Checkboxes have a default value of "on". https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#value */
    this.value = "on";
    /** Show the nested content, regardless of the checked state. Also hides the chevron. */
    this.showNested = false;
    this.hasSlottedCustomElements = false;
    this.hasSlottedNestedElements = false;
    this.formControlType = "checkbox";
  }
  updated(changedProperties) {
    if (changedProperties.has("indeterminate")) {
      this.control.indeterminate = this.indeterminate;
    }
  }
  render() {
    var _a;
    const checkboxHTML = html`<div
      class=${classMap({
      "bi-checkbox": true,
      "bi-checkbox--hasLabel": !!this.label
    })}
    >
      <input
        id=${this.randomId}
        class="bi-form-control bi-checkbox-control"
        type="checkbox"
        name=${ifDefined(this.controlName)}
        .value=${(_a = this.value) !== null && _a !== void 0 ? _a : ""}
        .checked=${this.checked}
        ?disabled=${this.disabled || this.fieldsetContext.disabled}
        ?required=${this.required}
        @input=${this.handleInput}
        @change=${this.handleChange}
      />
      <div class="bi-checkbox-icon--checked">
        <svg
          class="bi-checkbox-icon-inner"
          enable-background="new 0 0 10 9"
          viewBox="0 0 10 9"
          width="10"
          height="9"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 5.27283 1.61054-1.68419 1.88691 1.73538 5.18578-4.878708 1.31677 1.704458-6.54784 6.07332z"
          />
        </svg>
      </div>
      <div class="bi-checkbox-icon--indeterminate">
        <svg
          class="bi-checkbox-icon-inner"
          enable-background="new 0 0 8 1"
          viewBox="0 0 8 1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m0 0h8v1h-8z" />
        </svg>
      </div>
    </div>`;
    const nestedHTML = html`<div
      class=${classMap({
      "bi-checkbox-nested": true,
      "bi-checkbox-nested--hasNestedElements": this.hasSlottedNestedElements
    })}
    >
      <slot name="nested" @slotchange=${this.handleNestedSlotChange}></slot>
    </div>`;
    return html`${checkboxHTML} ${this.getContentHTML()} ${nestedHTML}`;
  }
  /** Checks the checkbox. */
  check() {
    this.checked = true;
    this.setValue(this.value);
  }
  /** Unchecks the checkbox. */
  uncheck() {
    this.checked = false;
    this.setValue(this.value);
  }
  /** Toggles the checkbox. */
  toggle() {
    this.checked = !this.checked;
    this.setValue(this.value);
  }
  /** Handles an input event on the checkbox. */
  handleInput(event) {
    this.checked = this.control.checked;
    super.handleInput(event);
  }
  getContentHTML() {
    if (this.label || this.error) {
      return html`<div class="bi-checkbox-content">
        ${this.getLabelHTML(this.hasSlottedNestedElements && !this.showNested)}
        ${this.getSublabelHTML()} ${this.getErrorHTML()}
      </div>`;
    } else {
      const classes = classMap({
        "bi-checkbox-custom": true,
        "bi-checkbox-custom--empty": !this.hasSlottedCustomElements
      });
      return html`<label class=${classes} for=${this.randomId}>
        <slot @slotchange=${this.handleCustomContentSlotChange}></slot>
      </label>`;
    }
  }
  handleCustomContentSlotChange() {
    this.hasSlottedCustomElements = this.slottedCustomElements.length > 0;
  }
  handleNestedSlotChange() {
    this.hasSlottedNestedElements = this.slottedNestedElements.length > 0;
  }
};
Checkbox.styles = checkboxStyles;
__decorate([property({
  type: Boolean,
  reflect: true
})], Checkbox.prototype, "checked", void 0);
__decorate([property({
  type: Boolean
})], Checkbox.prototype, "indeterminate", void 0);
__decorate([property()], Checkbox.prototype, "value", void 0);
__decorate([property({
  type: Boolean,
  attribute: "show-nested"
})], Checkbox.prototype, "showNested", void 0);
__decorate([queryAssignedElements()], Checkbox.prototype, "slottedCustomElements", void 0);
__decorate([queryAssignedElements({
  slot: "nested"
})], Checkbox.prototype, "slottedNestedElements", void 0);
__decorate([state()], Checkbox.prototype, "hasSlottedCustomElements", void 0);
__decorate([state()], Checkbox.prototype, "hasSlottedNestedElements", void 0);
Checkbox = __decorate([customElement("bi-checkbox")], Checkbox);
export { Checkbox };
