import { HttpClient } from '@angular/common/http'
import { DestroyRef, Injectable, inject } from '@angular/core'
import { environment } from '../../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { PractitionerSettings, PractitionerProfile } from '@shared/data/models/practitioner.model'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { TablePreferences } from 'src/app/modules/dashboard/data/dashboard.model'

@Injectable({
  providedIn: 'root'
})
export class PractitionerService {
  private httpClient = inject(HttpClient)
  private destroyRef = inject(DestroyRef)

  practitionerProfile = new BehaviorSubject<PractitionerProfile | undefined>(undefined)

  getPractitionerSettings(userId: string, hcsId: string): Observable<PractitionerSettings> {
    return this.httpClient.get<PractitionerSettings>(
      `${environment.dashboardApi}/tenant-api/practitioner/${userId}/${hcsId}/settings`
    )
  }

  updatePractitionerSettings(userId: string, hcsId: string, settings: PractitionerSettings) {
    return this.httpClient.post<PractitionerSettings>(
      `${environment.dashboardApi}/tenant-api/practitioner/${userId}/${hcsId}/settings`,
      settings
    )
  }

  getPractitionerProfile() {
    return this.httpClient.get<PractitionerProfile>(`${environment.dashboardApi}/tenant-api/practitioner/profile`)
  }

  initializePractitionerProfile() {
    this.getPractitionerProfile()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(profile => {
        this.practitionerProfile.next(profile)
      })
  }

  updateTablePreferences(preferences: TablePreferences) {
    return this.httpClient.post(`${environment.dashboardApi}/tenant-api/practitioner/table-preferences`, preferences)
  }

  updateProfile(profile: PractitionerProfile) {
    this.practitionerProfile.next(profile)
  }

  hideDiagnosesDisclaimer() {
    this.httpClient
      .post<PractitionerProfile>(`${environment.dashboardApi}/tenant-api/practitioner/accept-diagnoses-consent`, {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(profile => {
        this.practitionerProfile.next(profile)
      })
  }
}
