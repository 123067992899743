var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import textareaStyles from "./textarea.styles";
import { FormControl } from "../shared/components/form-control/form-control.component";
import { ifDefined } from "lit/directives/if-defined.js";
import "../button/button.component";
import * as tokens from "@mybingli/ds-tokens";
/**
 * A multi-line textarea.
 * @slot label-start - The content to show before the label. Useful for an icon, for example.
 * @slot label-end - The content to show after the label. Useful for a tooltip toggle, for example.
 * @cssprop [--bi-textarea-max-inline-size = 280px] - The maximum inline size of the textarea.
 * @cssprop [--bi-textarea-block-size = 82px (2 lines)] - The block size of the textarea.
 */
let Textarea = class Textarea extends FormControl {
  constructor() {
    super(...arguments);
    /** Whether the textarea expands to fit its container in an inline direction. */
    this.expand = false;
    /** The autocomplete attribute of the textarea. */
    this.autocomplete = "off";
    /** By default, the user can vertically resize the textarea. Set to `auto` to let the textarea automatically resize as its content grows. */
    this.resize = "vertical";
    /** The number of rows, which defines the initial height. */
    this.rows = 3;
  }
  updated(changedProperties) {
    if (changedProperties.has("value") || changedProperties.has("resize")) {
      this.updateBlockSize();
    }
  }
  disconnectedCallback() {
    var _a;
    super.disconnectedCallback();
    (_a = this.visibilityObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
  }
  render() {
    var _a;
    const textareaHTML = html`<textarea
      id=${this.randomId}
      class="bi-form-control bi-textarea"
      name=${ifDefined(this.controlName)}
      placeholder=${ifDefined(this.placeholder)}
      autocomplete=${this.autocomplete}
      rows=${this.rows}
      .value="${(_a = this.value) !== null && _a !== void 0 ? _a : ""}"
      ?disabled=${this.disabled || this.fieldsetContext.disabled}
      ?required=${this.required}
      @input=${this.handleInput}
      @change=${this.handleChange}
    ></textarea>`;
    return html` ${this.getLabelHTML()} ${textareaHTML}
    ${this.getSublabelHTML()} ${this.getErrorHTML()}`;
  }
  /**
   * Moves the focus to the textarea.
   * @param {FocusOptions} options An optional object for controlling aspects of the focusing process
   */
  focus(options) {
    this.control.focus(options);
  }
  /**
   * Removes the focus from the textarea.
   */
  blur() {
    this.control.blur();
  }
  updateBlockSize() {
    var _a;
    if (this.resize === "auto" && this.value) {
      this.control.style.blockSize = "auto";
      this.control.style.blockSize = `${((_a = this.control) === null || _a === void 0 ? void 0 : _a.scrollHeight) + tokens.biSizeBorderWeight * 2}px`;
    } else {
      this.control.style.blockSize = "";
    }
  }
};
Textarea.styles = textareaStyles;
__decorate([property()], Textarea.prototype, "placeholder", void 0);
__decorate([property({
  type: Boolean
})], Textarea.prototype, "expand", void 0);
__decorate([property()], Textarea.prototype, "autocomplete", void 0);
__decorate([property()], Textarea.prototype, "resize", void 0);
__decorate([property({
  type: Number
})], Textarea.prototype, "rows", void 0);
Textarea = __decorate([customElement("bi-textarea")], Textarea);
export { Textarea };
